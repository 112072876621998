export function SetPiwikData(gameCatCode, providerCode, actionType) {
    sessionStorage.setItem("isGamePage", true);
    /*
    if (gameCatCode === "Sportsbook") {
        switch (providerCode) {
            case "SBT":
                Pushgtagdata("Game", "Launch", "BTi_Sports_TopNav");
                break;
            case "IPSB":
                Pushgtagdata("Game", "Launch", "IM_Sports_TopNav");
                break;
            case "OWS":
                Pushgtagdata("Game", "Launch", "OW_Sports_TopNav(沙巴体育)");
                break;
            case "AIS":
                Pushgtagdata("Game", "Launch", "AI_Sports_TopNav");
                break;
     */
            // case "YBS":
            //     Pushgtagdata("Game", "Launch", "YBS_Sports_TopNav");
            //     break;
            /* case "VTG":
                Pushgtagdata("Game", "Launch", "V2G_Sports_TopNav");
                break; */
    /*
            default:
                break;
        }
    }
    */

    /*
    if (gameCatCode === "Esports") {
        switch (providerCode) {
            case "IPES":
                Pushgtagdata(
                    "Game​",
                    "Launch",
                    "IM_Esports_TopNav(乐天使电竞)"
                );
                break;
            case "TFG":
                Pushgtagdata("Game​", "Launch", "TF_Esports_TopNav");
                break;
            case "OWS":
                Pushgtagdata("Game​", "Launch", "OW_Esports_TopNav");
                break;
            default:
                break;
        }
    }
    if (gameCatCode === "InstantGames") {
        switch (providerCode) {
            case "SPR":
                Pushgtagdata("Game​", "Launch", "SPR_InstantGame_TopNav");
                break;
            case "AVIATOR":
                Pushgtagdata("Game​", "Launch", "Avatar_SPR_TopNav");
                break;

            default:
                break;
        }
    }
    if (gameCatCode === "Lottery") {
        switch (providerCode) {
            case "TCG":
                Pushgtagdata("Game", "Launch", "TC_Lottery_TopNav");
                break;
            case "BOY":
                Pushgtagdata("Game", "Launch", "BY_Lottery_TopNav");
                break;
            case "VRL":
                Pushgtagdata("Game", "Launch", "VR_Lottery_TopNav");
                break;
            case "YBK":
                Pushgtagdata("Game", "Launch", "OBG_Lottery_TopNav");
                break;
            case "SGW":
                Pushgtagdata("Game", "Launch", "SGW_Lottery_TopNav(双赢彩票)");
                break;
            case "LBS":
                Pushgtagdata("Game", "Launch", "LB_Lottery_TopNav");
                break;
            default:
                break;
        }
    }
    */
}

export function staticHeaderGtag(
    gameCatCode,
    providerCode,
    providerId,
    gameName,
    category
) {
    sessionStorage.setItem("isGamePage", true);
    /*
    let TypeGame = gameName ? "Game" : "Game Nav";
    let dataType = gameName ? "Launch" : category ? "" : "Launch";
    if (dataType === "") {
        return;
    } 
    if (gameCatCode === "InstantGames") {
        switch (providerCode) {
            case "SPR":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "SPR"}_InstantGame_ProductPage`
                );
                break;
            case "AVIATOR":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "Avatar"}_SPR_ProductPage`
                );
                break;

            default:
                break;
        }
    }
    if (gameCatCode === "LiveCasino") {
        switch (providerCode) {
            case "EVO":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "EVO"}_LiveDealer_ProductPage`
                );
                break;
            case "YBL":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${
                        gameName ? gameName : "YBL"
                    }_LiveDealer_ProductPage(醉天堂)`
                );
                break;
            case "BGG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "BG"}_LiveDealer_ProductPage`
                );
                break;
            case "GPI":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${
                        gameName ? gameName : "GPI"
                    }_LiveDealer_ProductPage(乐殿堂)`
                );
                break;
            case "AG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "AG"}_LiveDealer_ProductPage`
                );
                break;
            case "GDL":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "GD"}_LiveDealer_ProductPage`
                );
                break;
            case "ABT":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${
                        gameName ? gameName : "Allbet"
                    }_LiveDealer_ProductPage(隆运堂)`
                );
                break;
            case "NLE":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${
                        gameName ? gameName : "N2"
                    }_LiveDealer_ProductPage(双赢堂N2)`
                );
                break;
            case "SAL":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "SAG"}_LiveDealer_ProductPage`
                );
                break;
            case "NLE":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "NLE"}_LiveDealer_ProductPage`
                );
                break;
            case "EBT":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "eEBT"}_LiveDealer_ProductPage`
                );
                break;
            case "WEC":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "WEC"}_LiveDealer_ProductPage`
                );
                break;
            default:
                break;
        }
    }
    if (gameCatCode === "P2P") {
        switch (providerCode) {
            case "YBP":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "ANG"}_P2P_ProductPage(乐天使棋牌)`
                );
                break;
            case "BLE":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "BLE"}_P2P_ProductPage(BLE棋牌)`
                );
                break;
            case "KYG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "KYG"}_P2P_ProductPage(开元棋牌)`
                );
                break;
            case "JBP":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "JBP"}_P2P_ProductPage(双赢棋牌)`
                );
                break;
            case "Category":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${category ? category : "Category"}_P2P_ProductPage`
                );
                break;
            default:
                break;
        }
    }
    if (gameCatCode === "Slot") {
        switch (providerCode) {
            case "MGSQF": case "MGP":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "MG"}_Slots_ProductPage`
                );
                break;
            case "PT":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "PT"}_Slots_ProductPage`
                );
                break;
            case "TG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "PP"}_Slots_ProductPage`
                );
                break;
            case "DTG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "DTG"}_Slots_ProductPage`
                );
                break;
            case "SPG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "SG"}_Slots_ProductPage`
                );
                break;
            case "CW":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "CW"}_Slots_ProductPage`
                );
                break;
            case "BSG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "BSG"}_Slots_ProductPage`
                );
                break;
            case "AG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "AG"}_Slots_ProductPage`
                );
                break;
            case "PGS":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "PG"}_Slots_ProductPage`
                );
                break;
            case "YBF":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "YBF"}_Slots_ProductPage`
                );
                break;

            case "IMOPT":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "PT"}_Slots_ProductPage`
                );
                break;
            case "TG_SLOT":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "PP"}_Slots_ProductPage`
                );
                break;
            case "IMONET":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "NET"}_Slots_ProductPage`
                );
                break;
            case "SWF":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "SW"}_Slots_ProductPage`
                );
                break;
            case "CQG":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "CQ9"}_Slots_ProductPage`
                );
                break;
            case "FISHING":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${gameName ? gameName : "FISHING"}_Slots_ProductPage`
                );
                break;
            case "Category":
                Pushgtagdata(
                    TypeGame,
                    dataType,
                    `${category ? category : "Category"}_Slots_ProductPage`
                );
                break;
            default:
                break;
        }
    }
    */
}
