import React from 'react';
import { get, post } from '$ACTIONS/TlcRequest';
import { Button, Input, message, Spin, Row, Col, Modal, Form } from 'antd';
import { ApiPort } from '$ACTIONS/TLCAPI';
import { regforLogin, regforLoginReg } from '$ACTIONS/reg';
import Geetest from './Geetest';

import Router from 'next/router';
import CryptoJS from 'crypto-js';
import { getQueryVariable, Cookie, isGuestOpenGameUrl, isInIframeGamePage } from '$ACTIONS/helper';
import { Cookie as CookieUtil } from '$ACTIONS/util';

import Captcha from '../Captcha';
import { getMemberInfo } from '$DATA/userinfo';
import { SuccessIcon } from './RegisterIcnoSvg';
import { toast } from 'react-toastify';
import { userCenterActions } from '$STORE/userCenterSlice';
import { connect } from "react-redux";
import { setSmarticoLoginContinue } from "../../store/thunk/gameThunk";

import ForgotPwd from '@/ForgotPwd/';

const userName_reg = /^[a-zA-Z0-9]{6,14}$/;
const password_reg = /^(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\^#$@]*)([\^#$@]*[a-zA-Z0-9][\^#$@]*)+$/;

const CustomToast = ({ message }) => (
	<div className="custom-toast">
		<SuccessIcon />
		<span>{message}</span>
	</div>
);
class _Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			UserName: '',
			Password: '',
			userNameInputLengthIsValid: false,
			passwordInputLengthIsValid: false,
			loginAt: 'homepage',
			errorMessage: '', // for login modal
			captchaCode: '',
			isLogin: false,
			Spin: false,
			loadinglogin: false,
			isRegister: false,
			// OpenGeetest: false,
			challengeUuid: '',
			captchaVisible: false
		};

		this.Login = this.Login.bind(this);
		this.successLogin = this.successLogin.bind(this);
		this.isFirstLogin = null;
		this.isCaptchaOn = true;
	}

	componentDidMount() {
		let _href = global.location.href;
		if (_href.includes('openForgotPassword=1') && !_href.includes('#alreadyAutoOpen')) {
			setTimeout(()=>{ global.location.hash = '#alreadyAutoOpen'; },500)
			this.openForgotPasswordModal();
		}
		this.props.RefLogin && this.props.RefLogin(this);
		// QRT 参数自动登陆
		function decrypt(text, key) {
			let md5Key = CryptoJS.MD5(key).toString();
			let keyHex = CryptoJS.enc.Hex.parse(md5Key);
			let decrypt = CryptoJS.TripleDES.decrypt(text, keyHex, {
				mode: CryptoJS.mode.ECB,
				padding: CryptoJS.pad.Pkcs7
			});
			//解析数据后转为UTF-8
			let parseData = decrypt.toString(CryptoJS.enc.Utf8);
			return parseData;
		}

		// QRT加密参数登录
		const QRT = getQueryVariable('QRT');
		if (QRT) {
			const str = decrypt(decodeURIComponent(QRT), 'TLCQuickReg');
			const tokenArray = str.split('&');
			let tokenObject = {};
			tokenArray.forEach((v) => {
				let tokenKeyVal = v.split('=');
				tokenObject[tokenKeyVal[0]] = tokenKeyVal[1];
			});
			this.RefreshTokenApi(tokenObject.refreshToken, 'Bearer ' + tokenObject.token, tokenObject.isRegistration);
		}

		// redirectToken参数登录
		// const redirectToken = getQueryVariable('redirectToken');
		// if (redirectToken) {
		// 	console.log(redirectToken, global.redirectTokenVariable);
		// 	if (redirectToken === global.redirectTokenVariable) return;
		// 	global.redirectTokenVariable = redirectToken;
		// 	post(ApiPort.VerifyRedirectToken, {
		// 		Source: '8Shop',
		// 		Destination: 'TLCP4',
		// 		RedirectToken: redirectToken,
		// 		ClientId: 'TLC.Native.App',
		// 		ClientSecret: 'muitten',
		// 		Scope: 'Mobile.Service offline_access',
		// 		AppId: 'net.funpodium.tlc',
		// 		SiteId: '16'
		// 	})
		// 		.then((res) => {
		// 			if (res.isSuccess) {
		// 				// 传function代表临时退出，8shop会更新会员信息。
		// 				localStorage.getItem('access_token') && global.globalExit(true);
		// 				/**res.result.isNewlyCreated
		// 			 * 为true：会员从八号商店登录到官网，会包含memberInfo 参数
		// 			 * 为false：会员在官网登入，跳转至8号店，然后又从8号店跳转回去官网，不会包含memberInfo 参数
		// 			 */
		// 				if (res.result.isNewlyCreated) {
		// 					this.successLogin(res.result.accessToken, res.result.memberInfo, true);
		// 				} else {

		// 				}
		// 			} else {
		// 				Router.push('/cn/');
		// 			}
		// 		})
		// 		.catch((err) => {
		// 			console.log(err);
		// 		});
		// }

		if (localStorage.getItem('autoLogin')) {
			this.setState(
				{
					UserName: localStorage.getItem('userName'),
					Password: localStorage.getItem('userNum')
				},
				() =>
					setTimeout(() => {
						this.Login();
					}, 3000)
			);
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (
			prevState.userNameInputLengthIsValid !== this.state.userNameInputLengthIsValid ||
			prevState.passwordInputLengthIsValid !== this.state.passwordInputLengthIsValid
		) {
			if (this.state.userNameInputLengthIsValid && this.state.passwordInputLengthIsValid) {
				this.setState({
					errorMessage: ''
				});
			}
		}
	};

	RefreshTokenApi(refreshToken, memberToken, isRegistration) {
		const data = {
			grantType: 'refresh_token',
			clientId: 'TLC.Native.App',
			clientSecret: 'muitten',
			refreshToken: refreshToken
		};
		this.setState({ loadinglogin: true });
		const isOldAccount = !!localStorage.getItem('access_token');
		if (isOldAccount) {
			CookieUtil(null, null);
			localStorage.clear();
			sessionStorage.clear();
		}
		post(ApiPort.RefreshTokenapi, data, memberToken)
			.then((res) => {
				if (res) {
					if (res.access_token && res.refresh_token) {
						localStorage.setItem('access_token', JSON.stringify('bearer ' + res.access_token));
						get(ApiPort.GETMemberlistAPI)
							.then((memeberInfoResponse) => {
								this.setState({ loadinglogin: false });
								const memeberInfoResponseResultMemberInfo = memeberInfoResponse.result.memberInfo;
								let tokenMemberInfo = {
									loginOTP: memeberInfoResponse.result.memberNewInfo?.isLoginOTP
								};
								isRegistration === '1' && (this.isFirstLogin = true);
								for (const property in memeberInfoResponseResultMemberInfo) {
									tokenMemberInfo[
										property.substr(0, 1).toLocaleLowerCase() +
											property.substr(1, property.length - 1)
									] =
										memeberInfoResponseResultMemberInfo[property];
								}
								let _username = typeof tokenMemberInfo!=='string'  // note: i think the developer missed to refactor at commit e285095 in the past
											? tokenMemberInfo.userName
											: tokenMemberInfo;
								this.successLogin(res, _username, isOldAccount ? 'refresh' : undefined);
							})
							.catch((error) => {
								this.setState({ loadinglogin: false });
								console.log(error);
							});
					} else {
						this.setState({ loadinglogin: false });
						message.error('请重新登录,访问过期', 3);
						setTimeout(() => {
							global.globalExit();
							Router.push('/cn/');
						}, 2000);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loadinglogin: false });
			});
	}

	Login(name, pwd, Register) {
		const { UserName, Password, loginAt } = this.state;
		if (UserName.trim().length === 0) {
			if (loginAt === 'homepage') {
				message.error('请输入您的用户名。', 3);
				return;
			}
			this.setState({
				errorMessage: '请输入您的用户名。'
			});
			return;
		}
		if (Password.trim().length === 0) {
			if (loginAt === 'homepage') {
				message.error('请输入您的密码。', 3);
				return;
			}
			this.setState({
				errorMessage: '请输入您的密码。'
			});
			return;
		}

		if (!regforLogin.test(UserName) || !regforLogin.test(Password)) {
			message.error('用户名或密码格式错误');
			return;
		}
		if (!Register) {
			if (regforLoginReg.test(UserName) || regforLoginReg.test(Password)) {
				this.setState({ userNameValidateStatus: 'error' });
				message.error('请勿输入非法字符');
				return;
			}
		}
		let Times = this.Captcha ? this.Captcha.state.attempts : 3;
		if (Cookie.Get('FaileCoun' + UserName) >= Times) {
			if (this.state.challengeUuid == '') {
				this.setState({ captchaVisible: true });
				return;
			}
		}
		this.continueToLogin(name, pwd, Register);
		if (this.Captcha) {
			this.Captcha.getCaptchaInfo(UserName);
		}
	}
	continueToLogin = (name, pwd, Register) => {
		this.setState({
			loadinglogin: true,
			errorMessage: ''
		});
		const { UserName, Password } = this.state;
		const Postdata = {
			hostName: global.location.origin,
			captchaCode: Register == 'Register' ? '' : this.state.captchaCode,
			grantType: 'password',
			clientId: 'Fun88.CN.App',
			clientSecret: 'FUNmuittenCN',
			username: Register == 'Register' ? name : UserName.trim(),
			password: Register == 'Register' ? pwd : Password,
			scope: 'Mobile.Service offline_access',
			appId: 'net.funpodium.fun88',
			siteId: 37,
			deviceSignatureBlackbox: window.E2GetBlackbox
				? window.E2GetBlackbox().blackbox == '' || window.E2GetBlackbox().blackbox == undefined
					? ''
					: window.E2GetBlackbox().blackbox
				: '',
			challengeUuid: this.state.challengeUuid
		};
		post(ApiPort.PostLogin, Postdata)
			.then((res) => {
				this.setState({
					loadinglogin: false
				});
				// alert('login then ' + JSON.stringify(res))
				console.log('login then', res);
				if (res && res.isSuccess) {
					this.successLogin(res.result, Register == 'Register' ? name : UserName);
				} else {
					if (res && res.IS_FEAT_MAINTENANCE) {
						if (typeof this.props.hidemodal==='function') { // props.hidemodal is optional
							this.props.hidemodal();
						}
					} else {
					if (res.errors[0].errorCode == 'MEM00061') {
						message.error('您的账号无法使用，请联系在线客服！', 3);
					} else {
						message.error(res.errors[0].description || '登录失败！请稍后重试，或联系客服', 3);
					}
					}

				}
			})
			.catch((error) => {
				// alert('login error ' + JSON.stringify(error))
				console.log('login error', error);
				message.error(error.errors[0].description || '登录失败！请稍后重试，或联系客服', 3);
				this.setState({
					loadinglogin: false,
					challengeUuid: ''
				});

				// let errorList =["MEM00004","MEM00059","MEM00060","MEM00140","CAPTCHA_ERROR"]
				if (this.isCaptchaOn) {
					this.logInputFaile();
				}
			});
	};
	successLogin(token, userName, isRedirect) {
		console.log('---------->', token);
		/* note: bffscLogin will true; bffLogin bffRefreshToken bffscRefreshToken will false; */
		const IS_BFFSC_LOGIN_RESPONSE = Object.prototype.hasOwnProperty.call(token, 'accessToken') && typeof token.accessToken === 'object';
		if (IS_BFFSC_LOGIN_RESPONSE) {
			token = token.accessToken;
		}
		if (token && token.accessToken != null && token.accessToken != '') {
			this.setState({
				isLogin: true,
				//isResetPwd: member.revalidate,
				UserName: userName,
				isLoginVisible: false
			});
			Cookie.Delete('FaileCoun' + userName);
			localStorage.setItem('UserName', userName);
			//localStorage.setItem('PreferWallet', member.preferWallet);
			localStorage.setItem('access_token', JSON.stringify('bearer ' + token.accessToken));
			localStorage.setItem('refresh_token', JSON.stringify(token.refreshToken));
			//获取会员资料
			getMemberInfo((res) => {
				if (Object.keys(res).length) {
					this.props.setIsFollowInLogin(true);
				}
				// 第一次註冊後的登入，只顯示註冊成功彈窗
				if (this.state.isRegister) {
					console.log('hi~');
				} else {
					toast.info(<CustomToast message={'欢迎您，' + userName} />, {
						position: toast.POSITION.TOP_CENTER,
						toastClassName: 'login-success',
						toastId: 'login-success',
						autoClose: 1000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: false,
						draggable: false,
						closeButton: false,
						odyClassName: 'login-success'
					});
				}
				if (isRedirect) {
					Router.push('/cn/').then(() => {
						this.props.AlreadLogin(); // UI登录成功
					});
				} else {
					this.props.AlreadLogin(); // UI登录成功
				}
				this.setState({
					loadinglogin: false
				});
				//客服链接更正
				get(ApiPort.GETLiveChat, {viaInitialPageLoaded: true}).then((res) => {
					if (res && res.IS_FEAT_MAINTENANCE) {
						return;
					}
					if (res && res.url) {
						localStorage.setItem('serverUrl', res.url);
					}
					else if (res && res.result) {
						localStorage.setItem('serverUrl', res.result);
					}
				});
			}, true);
			//localStorage.setItem('memberInfo', JSON.stringify(memberRes));
			sessionStorage.setItem('isLogin', true);

			if (!this.props.home) {
				this.props.handleEvent(); // 关闭弹窗
			}

			//Piwik 追蹤 Benji//
			if (typeof _paq === 'object') {
				//_paq.push([ 'setUserId', member.memberCode ]);
			}
			//Piwik 追蹤 Benji//

			//this.props.getShopURL(); // 8号商城链接获取
			if (this.state.isRegister || this.isFirstLogin) {
				Router.push('/cn');
				toast.info(<CustomToast message="注册成功，欢迎来到本站" />, {
					position: toast.POSITION.TOP_CENTER,
					toastClassName: 'register-success',
					toastId: 'register-success',
					autoClose: 1000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					closeButton: false,
					odyClassName: 'register-success'
				});
				sessionStorage.setItem('isRegisterEvent', true);
				return;
			}
			if (isRedirect === 'refresh') {
				window.location.href = '/cn/';
				return;
			}

			if (this.props.smarticoLoginContinue) {
				Router.push('./cn/rewards-centre')
				this.props.setSmarticoContinue(false)
			}

			const FORCE_REFRESH_FOR_IFRAME_GAME = typeof window!=='undefined' && isGuestOpenGameUrl.run(window.location?.href) && isInIframeGamePage();
			if (FORCE_REFRESH_FOR_IFRAME_GAME) { // fixed this UX: Guest opened game (e.g. Sportsbook&name=SBT) iframe then login successful from top-right button.
				// alert('iframe-game-page url: ' + window.location.href);
				window.location.href = window.location.href.replace(isGuestOpenGameUrl.REGEXP, 'demo=false&') + '#REFRESH_AFTER_LOGIN_SUCC';
                return;
			}
		} else {
			this.setState({
				loadinglogin: false
			});
		}

		localStorage.removeItem('userName');
		localStorage.removeItem('userNum');
		localStorage.removeItem('autoLogin');
		localStorage.removeItem('RestrictAccessCode');
	}

	UserInput = (e, t) => {
		let tempValue = e.target.value;
		tempValue = tempValue.replace(/[<>.\s\/\\="']/, '');
		if (t == 'USER') {
			this.setState({
				UserName: tempValue,
				userNameInputLengthIsValid: tempValue.length != 0
				// tempValue.length >= 6 && tempValue.length <= 14,
			});
			localStorage.setItem('userName', tempValue);
		} else if (t == 'PWD') {
			this.setState({
				Password: tempValue,
				passwordInputLengthIsValid: tempValue.length != 0
				// tempValue.length >= 6 && tempValue.length <= 20,
			});
			localStorage.setItem('userNum', tempValue);
		} else {
			this.setState({
				captchaCode: tempValue
			});
		}
	};

	OnBlur = () => {
		const { UserName, Password } = this.state;
		if (UserName.trim().length === 0 && Password.trim().length === 0) {
			this.setState({ errorMessage: '请输入您的用户名或密码。' });
		} else if (UserName.trim().length === 0) {
			this.setState({ errorMessage: '用户名格式无效​' });
		} else if (Password.trim().length === 0) {
			this.setState({ errorMessage: '请输入您的密码。' });
		}
	};

	Registerdata(name, pwd) {
		this.setState({
			UserName: name,
			Password: pwd,
			isRegister: true
		});
	}

	HandleEnterKey = (e) => {
		if (this.state.loadinglogin != true) {
			if (localStorage.getItem('access_token') == null && this.state.isLogin == false) {
				if (this.props.home) {
					document.getElementById('SubmitGeet').click();
				} else {
					document.getElementById('SubmitGeetPopUp').click();
				}
			}
		}
	};

	/***是否开启图形验证*/
	getCaptchaSwitch = (name, pwd, Register) => {
		get(ApiPort.Captchaswitch).then((res) => {
			if (res) {
				if (res.isCaptchaOn) {
					// global.GeetestFaile = () => this.logInputFaile();
					this.isCaptchaOn = true;
				}
				this.continueToLogin(name, pwd, Register);
			}
		});
	};
	logInputFaile = () => {
		const { UserName } = this.state;
		const FaileCounName = 'FaileCoun' + UserName;
		/* 输入错误次数 */
		var count = Cookie.Get(FaileCounName);
		if (count == null || count == '') {
			//第一次输入错误
			Cookie.Create(FaileCounName, 1);
		} else {
			var count = Cookie.Get(FaileCounName);
			let num = ++count;
			if (count <= 2) {
				Cookie.Create(FaileCounName, num);
			} else {
				Cookie.Create(FaileCounName, num);
			}
		}
	};
	onMatch = (id) => {
		this.setState(
			{
				challengeUuid: id,
				captchaVisible: false
			},
			() => {
				this.Login();
			}
		);
	};
	openForgotPasswordModal() {
		this.refs.Showmodal.showModal(1);
		Pushgtagdata('ForgetPW_homepage');
        global.Pushgtagpiwikurl && global.Pushgtagpiwikurl("forget_info");
	}
	render() {
		const { UserName, Password, captchaVisible, sucessModal } = this.state;
		return (
			<div>
				{/* <Geetest
					Login={() => this.Login()}
					UserName={this.state.UserName}
					Password={this.state.Password}
					OpenGeetest={(e) => this.OpenGeetest(e)}
					ref={'GeetestPassCode'}
				/> */}
				<Captcha
					captchaVisible={captchaVisible}
					setCaptchaVisible={(v) => {
						this.setState({ captchaVisible: v });
					}}
					onMatch={this.onMatch}
					getCaptchaInfo={(props) => {
						this.Captcha = props;
					}}
				/>

				{/* 首页登录 */}
				{this.props.home ? (
					<Col span={12} className="tlc-sign">
						<Modal
							width="135px"
							keyboard={false}
							closable={false}
							className="Spin_modal"
							footer={null}
							visible={this.state.loadinglogin}
							maskClosable={false}
						>
							<Spin tip="玩命登录中..." />
						</Modal>
						<div className="login-wrap">
							<div className="forget-password">
								<Button
									type="link"
									size="small"
									onClick={()=>{
										this.openForgotPasswordModal()
									}}
								>
									忘记密码？
								</Button>
							</div>
							<div className="input-wrap">
								<Input
									placeholder="用户名"
									onChange={(e) => {
										this.setState({ loginAt: 'homepage' });
										this.UserInput(e, 'USER');
									}}
									onPressEnter={() => this.HandleEnterKey()}
									value={this.state.UserName}
									minLength={1}
									maxLength={20}
								/>

								<Input
									type="Password"
									placeholder="密码"
									onChange={(e) => {
										this.setState({ loginAt: 'homepage' });
										this.UserInput(e, 'PWD');
									}}
									onPressEnter={() => this.HandleEnterKey()}
									value={this.state.Password}
									minLength={1}
									maxLength={20}
								/>
							</div>
						</div>
						<div
							className="login-btn notice-section-btn offset-small-y"
							id="SubmitGeet"
							data-gt
							onClick={() => {
								this.setState({ loginAt: 'homepage' });
								this.Login();
								Pushgtagdata('Navigation', 'Click', 'Login_Home');
							}}
						>
							登录
						</div>

						<div
							className="register-btn notice-section-btn offset-small-y"
							onClick={() => {
								global.goUserSign('2');
								Pushgtagdata('Registration Nav', 'Click', 'Register_Home');
							}}
						>
							注册
						</div>
					</Col>
				) : (
					/* 弹窗登录 */
					<Spin spinning={this.state.loadinglogin} tip="玩命登录中...">
						<div className="user-modal">
							{this.state.errorMessage && <div className="login-error">{this.state.errorMessage}</div>}
							<div className="IputBox">
								<Input
									size="large"
									placeholder="用户名"
									prefix={<img src="/cn/img/icons/user.svg" />}
									onChange={(e) => {
										this.setState({ loginAt: 'modal' });
										this.UserInput(e, 'USER');
									}}
									onFocus={this.OnBlur}
									onBlur={this.OnBlur}
									onPressEnter={() => this.HandleEnterKey()}
									minLength={1}
									maxLength={20}
									value={this.state.UserName}
								/>
							</div>
							<div className="IputBox">
								<Input.Password
									type="Password"
									size="large"
									placeholder="密码"
									prefix={<img src="/cn/img/icons/password.svg" />}
									onChange={(e) => {
										this.setState({ loginAt: 'modal' });
										this.UserInput(e, 'PWD');
									}}
									onFocus={this.OnBlur}
									onBlur={this.OnBlur}
									onPressEnter={() => this.HandleEnterKey()}
									minLength={1}
									maxLength={20}
									value={this.state.Password}
								/>
							</div>
							<div className="link-box">
								<Button
									type="link"
									onClick={() => {
										this.props.openForgotPwd();
										Pushgtagdata('ForgetPW_login');
									}}
								>
									忘记用户名或密码？
								</Button>
							</div>
							<Button
								disabled={
									!this.state.userNameInputLengthIsValid || !this.state.passwordInputLengthIsValid
								}
								size="large"
								type="primary"
								block
								id="SubmitGeetPopUp"
								data-gt
								className="btn-cta"
								onClick={() => {
									this.setState({ loginAt: 'modal' });
									this.Login();
									Pushgtagdata('Login', 'Submit', 'Login');
								}}
							>
								登入
							</Button>
						</div>
					</Spin>
				)}
				<ForgotPwd ref="Showmodal" />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	smarticoLoginContinue: state.game.smarticoLogin
});

const mapDispatchToProps = function (dispatch) {
    return {
        setIsFollowInLogin: (isFollowInLogin = false) => {
            dispatch(
                userCenterActions.setIsFollowInLogin(isFollowInLogin)
            );
        },
		setSmarticoContinue: (isFromSmarticoLogin) => {
			dispatch(setSmarticoLoginContinue(isFromSmarticoLogin));
		},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(_Login);
